<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionMeetingtypes"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-narrow">
        <thead>
          <tr>
            <th>Active</th>
            <th>Meetingtype</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="meetingtype in editMeetingtypes"
            :key="meetingtype.MeetingtypeId"
          >
            <td width="50">
              <input
                type="checkbox"
                :id="'meetingtype' + meetingtype.MeetingtypeId"
                v-model="meetingtype.IsActive"
                :checked="meetingtype.IsActive"
              />
            </td>
            <td>
              <span
                ><label :for="'meetingtype' + meetingtype.MeetingtypeId">{{
                  meetingtype.MeetingtypeName
                }}</label></span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      editMeetingtypes: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      mOption: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    meetingtypes() {
      if (this.locationState) {
        return this.locationState.Meetingtypes
      } else {
        let meetingtypes = []

        if (this.channel) {
          for (let i = 0; i < this.channel.Meetingtypes.length; i++) {
            let mt = this.channel.Meetingtypes[i]

            meetingtypes.push({
              MeetingtypeId: mt.MeetingtypeId,
            })
          }
        }
        return meetingtypes
      }
    },
  },

  created() {
    this.mOption = JSON.parse(JSON.stringify(this.option))

    this.prepData()
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    checkOptionOnMeetingtype(meetingtypeId) {
      let result = false

      let mtIndex = this.option.MeetingtypeIds.findIndex(
        (m) => m === meetingtypeId
      )
      if (mtIndex > -1) {
        result = true
      }

      return result
    },

    prepData() {
      let self = this

      for (let i = 0; i < self.meetingtypes.length; i++) {
        self.editMeetingtypes.push({
          MeetingtypeId: self.meetingtypes[i].MeetingtypeId,
          MeetingtypeName: self.$options.filters.getMeetingtypeName(
            self.meetingtypes[i].MeetingtypeId
          ),
          IsActive: self.checkOptionOnMeetingtype(
            self.meetingtypes[i].MeetingtypeId
          ),
        })
      }
    },

    saveOptionMeetingtypes() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let meetingtypeIds = self.editMeetingtypes
          .filter((c) => c.IsActive)
          .map((m) => m.MeetingtypeId)

        optionProvider.methods
          .saveLocationOptionMeetingtypes(
            self.mOption.Id,
            self.locationId,
            meetingtypeIds
          )
          .then((response) => {
            self.isSavingSuccess = true

            self.setOption(response.data)

            let t = setTimeout(() => {
              self.onClickCancel()
              clearTimeout(t)
            }, 1500)
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
